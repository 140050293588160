.App {
  text-align: center;
}

.App-header {
  background-color: #f1f1f1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.App-Title {
  margin-top: 5vmin;
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-size: calc(16vmin);
  @apply tracking-wide;
  @apply font-thin;
  color: #4f4f4f;
}

.App-subTitle {
  margin-top: -6vmin;
  padding-top: 0px;
  font-size: calc(7vmin);
  word-spacing: 5.4vmin;
  color: #6f6f6f;
  @apply tracking-wider;
  @apply font-light;
}

.App-Email {
  color: #8f8f8f;
  font-size: calc(6.3vmin);
  margin-top: 80px;
  @apply tracking-wider;
  @apply font-serif;
}

.App-link {
  color: #61dafb;
}
